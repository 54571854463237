

.toggle {
    position: relative;
    cursor: pointer;
    height: 3.5rem;
    width: 3.5rem;
    background-color: var(--secondary);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .8rem;
    font-weight: 500;
    color: white;
    transition: all 400ms ease-in-out;
}

@media(hover:hover) {
    .toggle:hover {
        transition: all 200ms ease-in-out;
    }
}

@media(max-width:799px){
    .toggleContainer {
        z-index: 100;
        position: fixed;
        top: 2rem;
        right: 0;
        width: 5rem;
    }
}

@media(min-width:800px){
    .toggleContainer {
        position: relative;
        z-index: 100;
        /* top: 0;
        left: 0; */
        /* height: var(--header-height); */
        /* width: 6rem; */
        /* display: flex;
        align-items: center;
        justify-content: flex-end; */
    }
    
}

@media (min-width:1600px) {
    .toggle {
        height: 4rem;
        width: 4rem;
    }
}
@media (min-width:1800px) {
    .toggle {
        height: 4.5rem;
        width: 4.5rem;
    }
}


/* mask */

.mask {
    position: fixed;
    z-index: 98;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.50);

    pointer-events: none;
    opacity: 0;
    transition: all 350ms ease-in-out;
    backdrop-filter: blur(10px);
}
.mask.active {
    opacity: 1;
    pointer-events: all;
}


/* overlay */

.overlay {
    z-index: 99;
    position: fixed;
    top: 0;
    left: -100vw;
    bottom: 0;
    width: 100vw;
    background-color: white;
    color: var(--secondary);

    /* padding: var(--unit-sm); */
    padding: var(--space-md);

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: var(--space-sm);

    overflow-y: scroll;

    pointer-events: none;
    transition: all 500ms cubic-bezier(.7, .1, .2, 1);
}
.overlay.active {
    left: 0;
    pointer-events: all;
}
.overlay ul {
    list-style: none;
    margin-bottom: .75rem;
}
.overlay ul li {
    margin-bottom: .25rem;
}


@media(min-width:800px) {
    .overlay {
        left: -40vw;
        width: 40vw;
        padding: var(--unit-lg) var(--unit-lg) var(--unit-lg);
    }
    .overlay ul {
        list-style: none;
        margin-bottom: .75rem;
    }
    .overlay ul li {
        margin-bottom: .25rem;
    }
}