
.hosts {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
}


.partnerLogo,
.partnerLogoLarge {
    display: block;
    margin: 0 auto;
}
.partnerLogo {
    max-width: 10rem;
    max-height: 9rem;
}
.partnerLogoLarge {
    max-width: 14rem;
    max-height: 10rem;
}

.menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: var(--space-sm)
}




@media(max-width:799px) {
    .container {
        display: grid;
        grid-template-columns: 1fr;
        max-width: 35rem;
    }
    .section {
        padding: var(--space-md) 0;
    }
    .divider {
        border-top: 1px solid rgba(255, 255, 255, 0.5);
    }
}

@media(min-width:800px) {
    .container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
    
    .divider {
        border-left: 1px solid rgba(255, 255, 255, 0.5);
    }
    .section {
        padding: 0 var(--space-md);
    }
    .section:nth-child(1) {
        padding-left: 0;
    }
    .section:nth-child(3) {
        padding-right: 0;
    }

    .menu {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: var(--space-sm)
    }
    .legals {
        text-align: center;
    }
    
}