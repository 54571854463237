.button {
	cursor: pointer;
	display: inline-block;
	text-decoration: none;
	font-family: var(--default-font-family);
	padding: .75rem 2.5rem;
	border: 1px solid var(--secondary);
	border-radius: var(--default-button-radius);
	background-color: var(--secondary);
	color: white;
	transition: all 500ms ease-in-out;
}

.button:hover {
	transition: all 250ms ease-in-out;
	background-color: var(--primary);
	border-color: var(--primary);
	color: white;
}
.button.inverse:hover {
	transition: all 250ms ease-in-out;
	background-color: white;
	border-color: white;
	color: var(--primary);
}



.disabled {
	pointer-events: none;
	opacity: .5;
}