.header {
    position: relative;
    padding: var(--space-md);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
}

.layout {
    display: inline-grid;
    grid-template-columns: 1fr;
    gap: var(--space-sm);
}

.brandContainer {
    display: grid;
    gap: var(--space-sm);
    justify-content: space-between;
    align-items: center;
}
.brand {
    height: 2.25rem;
}

@media(max-width:799px){
    .venue {
        margin-bottom: var(--space-sm);
    }
}

@media(min-width:800px){
    .header,
    .shim {
        height: var(--header-height);
        width: 100%;
    }
    .header {
        position: absolute;
        z-index: 10;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .header.fixed {
        position: fixed;
        top: 0;
        margin-top: 0;
    }
    .brandContainer {
        display: grid;
        grid-template-columns: auto auto auto;
        gap: var(--space-sm);
        justify-content: space-between;
        align-items: center;
    }
    .venue {
        border-left: 1px solid var(--secondary);
        padding-left: var(--space-sm);
        line-height: 1.2;
    }
    
}

@media (min-width: 800px) {
    .header { padding: 0 var(--space-sm) 0; }
}
@media (min-width: 1200px) {
    .header { padding: 0 var(--space-sm) 0; }
}
@media (min-width: 1800px) {
    .header { padding: 0 var(--space-sm) 0; }
}

