:root {

    /* colour */

    --primary: #7A0FAF;
    --secondary: #FD6900;
    --accent: #71C6FF;
 
    --primary-wash: #e7d4ef;
    --secondary-wash: #e0c3af;
    --accent-wash: #e1f3fe;

    --bg-global: rgb(255, 255, 255);
    --bg-theme: var(--primary);

    --default-text-color: rgb(71, 71, 71);
    --default-heading-color: var(--primary);

    --wash: #F9F9FA;
    --light: #E1E2EA;
    --medium: #827e88;
    --dark: #430e5e;

    --error: rgb(255, 68, 0);
    --success: rgb(121, 173, 0);
    --warning: rgb(255, 166, 0);


    /* spacing */

    --container-max-width: 100rem;
    --container-adjacent-spacing-factor: 0.75;

    --unit-xxs: .5rem;
    --unit-xs: 1rem;
    --unit-sm: 2rem;
    --unit-md: 4rem;
    --unit-lg: 6rem;
    --unit-xl: 8rem;


    /* type */

    --default-font-family: 'Outfit', sans-serif;
    --default-font-weight: 300;
    --default-font-size: 1rem;
    --default-line-height: 1.4;

    --default-heading-line-height: 1.1;
    --default-heading-weight: 600;


    /* global UI styling */

    --layout-split: 40% 60%;

    --default-radius: 1rem;
    --default-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
    --default-shadow-size: 20px;
    --default-shadow-opacity: 0.2;

    --default-button-radius: 3rem;

    --gap-divider-color: currentColor;

    
    /* header */

    --header-height: 6.875rem;
    --header-height-mobile: 5rem;


    /* forms */

    --standard-border-color: var(--primary);
    --standard-field-radius: var(--default-button-radius);
    --standard-field-bg: white;


    /* layout */

    --stagger-offset: 6rem;

}